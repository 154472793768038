/* color #0c1a30
#5481a6
#0eafff */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.top-bar {
  color: #2a2a2a;
  background-color: #e6e8e6ff;
}

.top-bar-scale {
  transition: transform 0.4s ease;
  transform: scale(0);
}

.top-bar a {
  color: #4a4a4a !important;
}

.top-bar a:hover {
  color: #0eafff !important;
}

.logo {
  width: 250px !important;
}

.navbar {
  height: 100%;
  padding: 0 10px !important;
}

.navbar-nav {
  float: right !important;
  align-items: center !important;
  height: 100%;
}

.navbar-nav li {
  font-size: 1.2vw;
  margin: 0 0.6vw 0 0.6vw;
}

#navbarSupportedContent {
  display: block !important;
}

.dropdown-menu {
  padding: 0 !important;
  margin-top: 0 !important;
  border-radius: 0 !important;
  background-color: #0c1a30 !important;
}

.dropdown-menu li {
  margin: 0 !important;
}

.dropdown-menu li > a {
  padding: 15px 20px !important;
  color: #fff;
}

.dropdown-menu li > a:focus {
  padding: 15px 20px !important;
  color: #0c1a30 !important;
}

.box-shadow {
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.header-southern {
  z-index: 10;
  position: absolute;
  width: 100%;
  transition: all 0.5s ease;
}
.nav-wrapper {
  height: 100%;
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
}
.bd-footer {
  position: relative;
  bottom: 0;
  width: 100%;
}
.bd-footer a {
  text-decoration: none;
  color: #6a6a6a !important;
}

.footer-menu > li > a {
  color: #6a6a6a !important;
  outline: none !important;
}
.footer-menu > li > a:hover {
  color: #0eafff !important;
  outline: none !important;
}

.bd-footer a:hover {
  color: #0eafff;
}

.bd-footer i {
  color: #0eafff;
}

.bd-footer li {
  color: #6a6a6a;
}

.bd-footer p {
  width: 60%;
  text-align: justify !important;
}

.footer-credit p {
  width: 100% !important;
  text-align: center !important;
}
.footer-credit p {
  color: #fff !important;
}

.footer-credit a {
  color: #fff !important;
  text-decoration: none;
}

.footer-credit a:hover {
  color: #0eafff !important;
}
.bg-black {
  background-color: black;
}
.menu-border {
  border-top: 2px solid #0eafff !important;
}

.show > .dropdown-menu {
  display: block;
  position: absolute;
}

.hero {
  background-image: url(./img/packers.webp);
  width: 100% !important;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  max-height: 870px;
  min-height: 870px;
}
.hero-text {
  font-size: 39px;
  text-shadow: -2px 3px 8px #000;
  color: #fff;
}

.hero-about {
  position: relative;
  background-image: url(./img/about_header.webp);
  width: 100% !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-height: 250px;
  min-height: 250px;
  padding: 230px 0 110px;
}
.hero-about::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(50deg, #131313, transparent);
  opacity: 0.8;
  content: "";
}
.auto-container {
  position: relative;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
  width: 100%;
}

.hero-about .title {
  font-size: 36px;
  color: #ffffff;
  margin-bottom: 17px;
}
.page-breadcrumb {
  position: relative;
  margin-top: 5px;
  padding: 0 !important;
}
.page-breadcrumb li {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  padding-right: 13px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.page-breadcrumb li a {
  color: #0eafff !important;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.page-breadcrumb li:after {
  position: absolute;
  content: "\f105";
  right: -6px;
  top: 1px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  color: #ffffff;
}
.page-breadcrumb li:last-child {
  padding-right: 0px;
  margin-right: 0px;
}
.page-breadcrumb li:last-child::after {
  display: none;
}
.page-breadcrumb li a:hover {
  color: #ffffff;
}
.quick-quote-form > div {
  width: 100%;
  border: 0 !important;
  background-color: none !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.21);
  border: 1px solid #dadada;
  border-radius: 0 !important;
}

.quick-quote-form > div > input {
  border-radius: 0 !important;
  background-color: #0000003d !important;
  padding-right: 26px !important;
}
.quick-quote-form > .btn:hover {
  color: #000;
  background: #fff;
}
.input-group > span {
  width: 40px;
  height: 40px;
  background-color: #ffffff29;
  border-radius: 0 !important;
  color: #fff !important;
}

.input-group > input::placeholder {
  color: #eee;
}
.input-group > input:focus {
  box-shadow: none;
  border-color: #0eafff;
}
.form-control {
  color: #fff !important;
}
.content-hero {
  width: 80%;
}
.hero-form {
  max-width: 400px;
  padding: 15px 45px;
  background: #00336666;
  backdrop-filter: blur(15px);
  color: #fff;
  text-shadow: none;
  -webkit-box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 31%);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.31);
  box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 31%);
}

.hero-form .btn {
  background-color: #0eafff !important;
  border: 1px solid #0eafff !important;
}

.hero-form .btn:hover {
  background-color: #fff !important;
  color: #0eafff;
  border: 1px solid #0eafff;
}

.hero-form-modal {
  padding: 40px 45px !important;
}

.form-contact {
  max-width: 400px;
  margin: 0 auto;
  padding: 35px 50px;
  background: #003366;
  color: #fff;
  text-shadow: none;
  -webkit-box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 31%);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.31);
  box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 31%);
}

.form-contact .input-group > span {
  width: 40px;
  height: 40px;
  background-color: #ffffff29;
  border-radius: 0 !important;
  color: #fff !important;
}

.form-contact .input-group > input::placeholder {
  color: #eee;
}
.form-contact .input-group > input:focus {
  box-shadow: none;
  border-color: #fff;
}
.form-contact .form-control {
  color: #fff !important;
}

.form-contact .btn {
  background-color: #0eafff;
  color: #fff;
  border: 1px solid #fff;
}

.form-contact .btn:hover {
  color: #0eafff;
}

.bg-navbar {
  backdrop-filter: blur(10px);
  background: #003366a5 !important;
  box-shadow: 0px 0px 30px rgba(227, 228, 237, 0.37);
  transition: all 00.5s ease-in-out;
}
.nav-item a {
  padding: 0 0.55rem !important;
}

.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;
  background-color: #0eafff !important;
}

.dropdown-item:hover {
  background-color: #0eafff !important;
}
.nav-link {
  color: #000 !important;
  line-height: 90px;
}
.nav-bt {
  height: 90px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  top: 38px;
  transition: all 0.3s ease;
}
.sticky {
  top: 0;
  height: 90px;
  margin-bottom: -38px;
  padding: 10px !important;
  opacity: 1;
  background-image: linear-gradient(90deg, #003366 0, #003366 48%, #003366 70%, #003366);
  transition: all 0.5s ease;
  box-shadow: 0.2rem 0.5rem 1rem rgb(0 0 0 / 25%);
}

.sticky-toggler {
  border: 0 solid rgba(0, 0, 0, 0.15) !important;
  box-shadow: none !important;
}

.sticky-toggler span {
  background-color: #000 !important;
  transition: color 0.3s ease;
}

.sticky nav > div > div > ul > li > a {
  color: #fff !important;
  transition: color 0s ease;
}

.topMargingOffset {
  padding-top: 38px !important;
}
.justify-content {
  justify-content: flex-end !important;
}

.position {
  position: fixed !important;
}

.top-bar .btn {
  color: #0c1a30 !important;
  outline: 0 !important;
  border: 0 !important;
}

@media (min-width: 597px) {
  .content-hero {
    width: 95% !important;
    transition: width 0.3s ease;
  }
}

@media (max-width: 697px) {
  .col-hero-text {
    display: none !important;
    transition: display 0.5s ease;
  }
  .content-hero {
    justify-content: center !important;
    transition: all 0.3s ease;
  }
  .col-custom {
    display: flex !important;
    flex: 0 0 auto;
    width: 100% !important;
    justify-content: center !important;
  }

  .cta-2 > div {
    padding: 0 10px !important;
  }
}
@media (max-width: 794px) {
  .hero-text {
    font-size: 26px !important;
    transition: font-size 0.5s ease;
  }

  .cta-2 > div > div {
    margin-bottom: 20px !important;
  }

  #counter {
    margin-top: 0 !important;
    max-width: 100% !important;
    transition: all 0.3s ease;
  }

  .bg-overlay {
    padding: 60px 0 70px 0 !important;
  }
}

@media (min-width: 794px) {
  .hero-text {
    font-size: 30px !important;
    transition: font-size 0.5s ease;
  }
  #counter {
    margin-top: -90px !important;
    transition: margin-top 0.3s ease;
  }
}

@media (min-width: 1200px) {
  .hero-text {
    font-size: 45px !important;
    transition: font-size 0.5s ease;
  }

  .cta-1-i,
  .cta-1-h4 {
    font-size: 38px !important;
  }
  .cta-1-p {
    font-size: 19px !important;
  }
  .cta-1-btn {
    font-size: 24px !important;
  }

  .cta-2-i,
  .cta-2-h4 {
    font-size: 38px !important;
  }
  .cta-2-p {
    font-size: 19px !important;
  }
  .cta-2-btn {
    font-size: 24px !important;
  }
}

@media (max-width: 1200px) {
  .cta-1-i,
  .cta-1-h4 {
    font-size: 28px !important;
  }
  .cta-1-p {
    font-size: 14px !important;
  }
  .cta-1-btn {
    font-size: 20px !important;
  }

  .cta-2-i,
  .cta-2-h4 {
    font-size: 28px !important;
  }
  .cta-2-p {
    font-size: 14px !important;
  }
  .cta-2-btn {
    font-size: 20px !important;
  }
}

@media (max-width: 1100px) {
  .navbar-nav li {
    font-size: 12px !important;
    margin: 0 2px !important;
  }
  .side {
    font-size: 14px !important;
  }
}

@media (min-width: 1150px) {
  .hero-text {
    font-size: 45px !important;
    transition: font-size 0.5s ease;
  }
}
@media (max-width: 992px) {
  .hero-text {
    /* font-size: 29px !important; */
  }

  .cta-1-h4,
  .cta-1-i {
    font-size: 24px !important;
  }
  .cta-1-p {
    font-size: 14px !important;
  }
  .cta-1-btn {
    font-size: 16px !important;
  }

  .cta-2 {
    text-align: center;
  }
  .cta-2-h4,
  .cta-2-i {
    font-size: 24px !important;
  }
  .cta-2-p {
    font-size: 14px !important;
  }
  .cta-2-btn {
    font-size: 16px !important;
  }

  #counter {
    margin-top: -90px !important;
    transition: margin-top 0.3s ease;
  }
}
@media (min-width: 993px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    transition: display 0.5s ease;
  }

  .dropdown-toggle::after {
    content: none !important;
  }

  #counter {
    margin-top: -80px !important;
    transition: margin-top 0.3s ease;
  }
}

@media (max-width: 992px) {
  .top-bar .top-bar-scale {
    display: none !important;
  }
  .side {
    display: none !important;
  }
  .position {
    position: fixed !important;
  }
  .sticky nav > div > div > ul > li > a {
    color: #fff !important;
  }
}

@media (max-width: 992px) {
  .navbar-collapse a {
    color: black !important;
  }
  .navbar-nav {
    width: 100%;
    font-size: 6vh !important ;
    margin: 0 !important ;
    align-items: flex-start !important;
    padding: 0 !important;
  }
  .navbar-nav li {
    margin: 0 !important;
  }
  .navbar li > a {
    color: #fff !important;
  }
  .nav-link {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    line-height: 24px !important;
  }

  .menu-border {
    border-top: 0 !important;
  }
  .dropdown-menu li > a {
    color: #fff !important;
  }

  .nav-item {
    width: 100%;
    padding: 15px 0 !important;
  }
  .header-top {
    display: none !important;
  }
  .nav-bt {
    top: 0 !important;
    padding: 0 !important;
  }
  .navbar-collapse {
    position: absolute !important;
    top: 91px;
    width: 100%;
    justify-content: center !important;
    left: 0;
    background-color: #0c1a30;
    padding: 15px;
    height: auto !important;
    transition: left 0.3s ease;
  }

  .justify-content {
    justify-content: center !important;
  }

  .collapse {
    left: -100% !important;
    transition: left 0.5s ease;
  }

  .navbar-collapse.collapsing {
    height: 100%;
    left: -75%;
    transition: height 0s ease;
  }

  .navbar-collapse.show {
    height: 100%;
    left: 0;
    transition: left 400ms ease-in-out;
  }

  .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: left 400ms ease-in;
  }
  .navbar-nav li {
    font-size: 18px;
  }

  .navbar-brand{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.navbar-collapse:not(.collpse) + .navbar-nav {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  transition: transform 0.5s ease;
}

.navbar-toggler{
  border: 0 !important;
  box-shadow: none !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler:hover {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.navbar-toggler span {
  display: block;
  background-color: #0eafff !important;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  position: relative;
  left: 0;
  opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  -webkit-transition: transform 0.35s ease-in-out;
  -moz-transition: transform 0.35s ease-in-out;
  -o-transition: transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  opacity: 0.9;
}

#navbarSupportedContent {
  display: none !important;
}

/* 
  a line-height: 100px;
      height: 100%;
      padding: 0 0.5rem;
  
  
      li      line-height: 100px;
      align-items: center;
  
      ul height: 100px;
      align-items: inherit;
  
  */

.fa-bg-white {
  position: absolute;
  color: red;
  right: 9px;
}
.fa-bg-white::before {
  position: relative;
  z-index: 1;
}

.fa-bg-white::after {
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  z-index: 0;
  top: 10%;
  left: 10%;
  width: 10px;
  height: 10px;
}

.mail-status {
  font-size: 0.8rem !important;
}

.side {
  height: 100%;
  border: 0 !important;
  border-radius: 0 !important;
  background: #0eafff !important;
  color: white !important;
  text-transform: uppercase;
  font-weight: 600 !important;
}

.services {
  padding: 60px 0 60px 0;
  text-align: center !important;
}

.section-title {
  align-items: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #000;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 0;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #0eafff;
  bottom: -1px;
  left: calc(50% - 20px);
}

/* .row-services{
    border:1px solid #ebeef0;
  } */

.service-item {
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.861);
}

.service-item h4 {
  font-weight: 700;
}

.border-right {
  border: 1px solid #dededea6;
}
.service-item:hover {
  background-color: #0eafff;
  color: #fff;
  transition: all 0.3s ease;
  transform: translateY(-5px);
}

.service-item:hover .icon-bg {
  color: #fff;
  transition: all 0.4s ease;
}

.service-item:hover .icon-color {
  color: #0eafff;
  transition: all 0.4s ease;
}

.service-item:hover p {
  color: #fff;
}

.icon-bg {
  color: #0eafff;
  transition: all 0.4s ease;
}

.icon-color {
  color: #fff;
  transition: all 0.4s ease;
}

.color-primary {
  color: #0eafff !important;
}

.fs-40 {
  font-size: 32px !important;
}

.box-title {
  color: #000;
  transition: color 0.5s;
}

.custom-box-fixed {
  position: relative;
  background-color: #0eafff !important;
}
.custom-box-fixed i {
  color: #fff !important;
}
.custom-box-fixed h4 {
  color: #fff !important;
}

.custom-box {
  position: relative;
  background-size: 100% 200%;
  background-image: linear-gradient(to bottom, #ffffffa3 50%, #0eafff 50%);
  -webkit-transition: background-position 0.5s;
  -moz-transition: background-position 0.5s;
  transition: background-position 0.5s;
}

.custom-box:hover {
  background-position: 0 100%;
}

.custom-box:hover .color-primary {
  color: #fff !important;
  transition: color 0.4s;
}

.custom-box:hover .box-title {
  color: #fff !important;
  transition: color 0.3s;
}

.section-bg {
  padding: 0 !important;
  position: relative;
  overflow: hidden;
  background-image: url("./img/moving.webp");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

/* .section-bg::before{
    background-image: url('./img/moving.png');
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      content: '';
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      will-change: transform;
      z-index: -1;
  }
   */

.bg-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.81);
  padding: 60px 0 150px 0;
}

.bg-overlay-dark {
  width: 100%;
  height: 100%;
  background-color: rgba(22, 22, 22, 0.81);
  padding: 60px 0 60px 0 !important;
}

.section-bg h2 {
  color: #000;
}

.section-bg p {
  color: #000;
}

#counter {
  margin-top: -80px;
  background-color: #0c1a30 !important;
  position: relative;
}

.counts {
  color: #0eafff;
  font-size: 52px;
  font-weight: 700;
}

.counts-desc {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.our-process {
  padding: 60px 0 30px 0;
  overflow: hidden;
  text-align: center !important;
}

.row-process ul li {
  list-style: none;
  display: inline-block;
  margin: 0px 0px 30px;
  position: relative;
  padding: 0px 68px;
}

.row-process ul li h6 {
  margin: 0px;
  font-weight: 600;
  letter-spacing: 0.8px;
}

.row-process i {
  font-size: 50px !important;
  padding: 33px 0 0 0;
  color: #0eafff;
  transition: all 0.5s ease-in-out;
}

.row-process span {
  display: inline-block;
  border: 2px solid #0eafff;
  width: 120px;
  height: 120px;
  position: relative;
  margin: 0px 0px 23px;
  transition: all 0.5s ease-in-out;
}

.row-process span > small {
  position: absolute;
  left: 50%;
  margin: -23px 0px 0px -22px;
  top: 0px;
  font-weight: bold;
  width: 45px;
  height: 45px;
  border: 6px solid #ffffff;
  border-radius: 100%;
  font-size: 14px;
  color: #ffffff;
  padding: 5px 0;
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.row-process span > a {
  background-color: #0c1a30;
  position: absolute;
  right: -23px;
  margin: -22px 0px 0px 0px;
  top: 50%;
  font-weight: bold;
  width: 45px;
  height: 45px;
  border: 6px solid #ffffff;
  border-radius: 100%;
  color: #ffffff;
  padding: 9px 0px 0px;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  text-decoration: none !important;
}

.row-process ul li:last-child span > A {
  background-color: #198754 !important;
}

.row-process ul li:hover span > a {
  opacity: 1 !important;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.row-process > ul li:hover span > small {
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.row-process ul li:hover span {
  background-color: #0eafff;
  border: 2px solid #0eafff;
  transition: all 0.5s ease-in-out;
}
.row-process ul li:hover span > i {
  color: #fff;
  transition: all 0.5s ease-in-out;
}

.row-process ul li h6 {
  color: #003366;
  font-size: 1.2rem;
  font-weight: 600;
}
.row-process ul li::before {
  content: "\f178";
  font-family: FontAwesome;
  position: absolute;
  right: -12px;
  top: 47%;
  font-size: 29px;
  margin: -37px 0px 0px;
}

.row-process ul li:last-child:before {
  content: none;
}

@media (max-width: 770px) {
  .row-process ul li::before {
    content: none !important;
  }
}

@media (max-width: 992px) {
}

.process-bg {
  background-color: #003366;
}

.why-us {
  padding: 60px 0 60px 0;
  overflow: hidden;
  text-align: center !important;
  background-color: #282828;
}

.why-us .section-title h2 {
  color: #fff !important;
}
.why-us .section-title p {
  color: #ccc;
}

.why-us-item {
  transition: all 0.3s ease;
  background: #0eafffe0;
  box-shadow: 0px 0px 10px 0px rgb(255 255 255 / 6%);
}

.why-us-item h4 {
  color: #fff;
}

.why-us-item p {
  color: #fff;
  font-weight: 400;
}

.border-right {
  border: 1px solid #dededea6;
}
.why-us-item:hover {
  background-color: #0c1a30;
  color: #0eafff !important;
  transition: all 0.3s ease;
  transform: translateY(-5px);
}

.why-us-item h4 {
  color: #fff;
  font-weight: 600;
}

.why-us-item:hover h4 {
  color: #0eafff;
}

.why-us-item:hover .why-us-icon-bg {
  color: #0eafff !important;
  transition: all 0.4s ease;
}

.why-us-item:hover .why-us-icon-color {
  color: #fff;
  transition: all 0.4s ease;
}

.why-us-item:hover p {
  color: #fff;
}

.why-us-icon-bg {
  color: #fff;
  transition: all 0.4s ease;
}

.why-us-icon-color {
  color: #0eafff;
  transition: all 0.4s ease;
}

.cta-1 {
  margin-top: -65px;
  width: 80%;
}

.cta-1-i,
.cta-1-h4 {
  font-size: 36px;
}

.cta-1-p {
  font-size: 19px;
}

.cta-1-btn {
  font-size: 24px;
}

.cta-1-btn:hover {
  background-color: #0eafff !important;
  border-color: #fff !important;
  transition: all 0.2s ease;
}

.cta-2 {
  margin-top: 0;
  padding: 50px;
  width: 80%;
}

.cta-2 > div {
  padding: 0 90px;
}

.cta-2-i,
.cta-2-h4 {
  font-size: 36px;
}

.cta-2-p {
  font-size: 19px;
}

/* .cta-2-btn{
    font-size: 24px;
    padding: 10px;
  }
  
  .cta-2-btn:hover{
  background-color: #0eafff !important;
  border-color: #fff !important;
  transition: all 0.2s ease;
  } */

.cta-2-btn {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 28px;
  padding: 15px 40px !important;
  font-weight: 800;
  overflow: hidden;
  color: #fff;
  z-index: 1;
  border: 0;
  background-color: #0eafff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}
.cta-2-btn:before {
  left: 0;
  top: 0;
  content: "";
  background-color: #fff;
  border-radius: 10px;
  height: 100%;
  width: 24px;
  position: absolute;
  -webkit-transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.cta-2-btn:hover {
  color: #0eafff;
}

.cta-2-btn .btn-title {
  position: relative;
}
.cta-2-btn:hover .btn-title {
  color: #0eafff;
}
.cta-2-btn:hover:before {
  width: 100%;
}

.footer_logo {
  margin-top: -15px;
}

.about-section {
  padding-top: 120px;
}

.about-section a {
  text-decoration: none;
}

.about-section .content-column {
  position: relative;
  margin-bottom: 40px;
  z-index: 1;
}

.about-section .content-column .inner-column {
  position: relative;
  padding-left: 70px;
}

.about-section .content-column .sec-title {
  margin-bottom: 40px;
}

.sec-title {
  position: relative;
  margin-bottom: 50px;
  z-index: 2;
}
.sec-title .sub-title {
  position: relative;
  top: -8px;
  font-size: 18px;
  color: #6a6a6a;
  line-height: 1.2em;
  font-weight: 500;
  display: inline-block;
  padding-left: 20px;
}
.sec-title .sub-title:before {
  position: absolute;
  left: 0;
  top: 0;
  letter-spacing: 0.2em;
  content: "//";
  color: #0eafff;
}
.sec-title h2 {
  position: relative;
  color: #000;

  margin-top: -5px;
  margin-bottom: 0;
}

.text {
  color: #6a6a6a;
  font-size: 16px;
  font-weight: 500;
  line-height: var(--body-line-height);
}

.sec-title .text {
  margin-top: 30px;
}
.about-section .content-column .list-style-two {
  margin-bottom: 40px;
}
.list-style-two {
  position: relative;
  list-style: none;
  padding: 0;
}

.list-style-two li i {
  color: #0eafff;
}

.list-style-two li {
  position: relative;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}

.about-section .content-column .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.about-section .content-column .info-btn {
  position: relative;
  display: block;
  font-size: 22px;
  padding: 8px 0;
  padding-left: 90px;
  color: #0eafff !important;
  text-align: left;
  font-weight: 700;
  white-space: nowrap;
  line-height: 20px;
  margin-right: 30px;
}

.about-section .content-column .info-btn small {
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #6a6a6a;
  margin-bottom: 10px;
}

.about-section .content-column .info-btn i {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -34px;
  line-height: 68px;
  height: 68px;
  width: 68px;
  text-align: center;
  border-radius: 50%;
  color: #003366;
  background-color: #0eafff;
  font-size: 24px;
}

.btn-style-one {
  position: relative;
  font-size: 16px;
  line-height: 28px;
  padding: 15px 50px;
  font-weight: 800;
  overflow: hidden;
  color: #ffffff;
  z-index: 1;
  background: #0eafff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 10px;
  overflow: hidden;
}
.btn-style-one:before {
  left: 0;
  top: 0;
  content: "";
  background-color: #003366;
  border-radius: 10px;
  height: 100%;
  width: 24px;
  position: absolute;
  -webkit-transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.btn-style-one:hover {
  color: #ffffff;
}
.btn-style-one:hover .btn-title {
  color: #ffffff;
}
.btn-style-one:hover:before {
  width: 100%;
}

.theme-btn .btn-title {
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.about-section .image-column {
  position: relative;
  margin-bottom: 35px;
}

.about-section .image-column .inner-column {
  position: relative;
  padding-right: 110px;
}

.about-section .image-column .image-1 {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 0px;
}

.about-section .image-column .image-1 img {
  width: 100%;
}

.about-section .image-column .image-2 {
  position: absolute;
  right: 0;
  top: 60px;
  margin-bottom: 0;
  border-radius: 10px;
  overflow: hidden;
  border: 5px solid #ffffff;
  -webkit-box-shadow: 0 10px 60px rgb(0 0 0 / 10%);
  box-shadow: 0 10px 60px rgb(0 0 0 / 10%);
}

@media (max-width: 597px) {
  .about-section .image-column .image-2 {
    display: none;
  }
  .about-section .image-column .inner-column {
    padding: 0;
  }

  .about-section {
    padding-top: 60px !important;
  }

  .sec-title .text {
    margin-top: 30px;
    text-align: justify;
  }

  .about-section .content-column .inner-column {
    padding: 25px !important;
  }

  .about-section .content-column .btn-box {
    flex-direction: column !important;
  }

  .about-section .content-column .info-btn {
    margin-right: 0 !important;
    margin-bottom: 30px;
  }
}

.network {
  padding: 60px 0 60px 0;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.page-content {
  white-space: pre-wrap;
  text-align: justify;
  color: #3e3d3d;
  font-size: 18px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #fff;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #0eafff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.gallery {
  padding: 60px;
}

.contact-section {
  padding: 60px 0 60px 0;
}
.lightbox-gallery {
  background-image: linear-gradient(#4a148c, #e53935);
  background-repeat: no-repeat;
  color: #000;
  overflow-x: hidden;
}
.lightbox-gallery p {
  color: #fff;
}
.lightbox-gallery h2 {
  font-weight: bold;
  margin-bottom: 40px;
  padding-top: 40px;
  color: #fff;
}
@media (max-width: 767px) {
  .lightbox-gallery h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}
.lightbox-gallery .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto 40px;
}
.lightbox-gallery .intro p {
  margin-bottom: 0;
}
.lightbox-gallery .photos {
  padding-bottom: 20px;
}
.lightbox-gallery .item {
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .dbox {
    margin-bottom: 0;
    padding: 0;
  }
}
.dbox {
  width: 100%;
  margin-bottom: 25px;
  padding: 0 20px;
}

.dbox .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #0eafff;
  margin: 0 auto;
  color: #fff;
  font-size: 20px;
  margin-bottom: 20px;
}
.dbox .text {
  width: 100%;
}

.dbox p {
  margin-bottom: 0;
}

.dbox p span {
  font-weight: 500;
  color: #0eafff;
  display: block;
}

.dbox p a {
  color: #6a6a6a !important;
  text-decoration: none;
}

.dbox p a:hover {
  color: #0c1a30 !important;
}

.c-address:hover .icon {
  background: #fff;
  color: #0eafff;
  border: 1px solid #0eafff;
}

.c-phone:hover .icon {
  background: #fff;
  color: #0eafff;
  border: 1px solid #0eafff;
}

.c-email:hover .icon {
  background: #fff;
  color: #0eafff;
  border: 1px solid #0eafff;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-gallery > div {
  height: 480px;
  position: relative;
  flex: 1 1 auto;
}

.image-gallery div img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: 5px;
}

.image-gallery::after {
  content: "";
  flex-grow: 999;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease;
}

.modal-main {
  position: fixed;
  background: #000000c4;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease;
  z-index: 2;
}

.close-modal {
  float: right !important;
  margin: -20px;
  cursor: pointer;
}

.modal-backdrop {
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1 !important;
}

.mobile-cta {
  left: 0;
  bottom: 30px;
  width: 100%;
  max-width: 100%;
  transition: all 0.3s ease;
}

.mobile-cta .mobile-cta-content {
  background-color: #0eafffd8;
  margin: 0rem 4rem 0rem 4rem;
  padding: 10px 2px 10px 2px;
  backdrop-filter: blur(2px);
  border-radius: 50px 50px 50px 50px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.mobile-cta-content .cta-list {
  list-style-type: none;
  margin: 0 !important;
  padding: 0 !important;
}
.cta-item {
  margin-left: 10px;
  margin-right: 10px;
}
.cta-item > a {
  font-size: 20px !important;
  text-decoration: none !important;
  font-weight: 500;
  color: #fff;
}
.cta-item,
a:focus,
a:visited,
a:link {
  color: #fff !important;
  outline: none !important;
}
.navbar-nav a:focus,
a:active {
  color: #0eafff !important;
}

.navbar-nav a:hover {
  background-color: #0eafff !important;
  color: #fff !important;
}

.cta-item-text {
  padding-left: 4px !important;
}
.cta-item:not(:last-child)::after {
  content: "";
  border-color: #000;
  width: auto;
  left: auto;
  right: auto;
  position: relative;
  height: 100%;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  border-left-width: 1px;
  border-style: solid;
  right: calc(-22px / 2);
}

@media (min-width: 770px) {
  .mobile-cta {
    display: none !important;
    transition: all 0.3s ease;
  }
}
